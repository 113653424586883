import { TimeLocaleDefinition } from 'd3-time-format';
import { t } from '@superset-ui/core';

export const getD3Locale = (): TimeLocaleDefinition => ({
  dateTime: '%A, %e %B %Y г. %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    t('Sunday'),
    t('Monday'),
    t('Tuesday'),
    t('Wednesday'),
    t('Thursday'),
    t('Friday'),
    t('Saturday'),
  ],
  shortDays: [
    t('SUN'),
    t('MON'),
    t('TUE'),
    t('WED'),
    t('THU'),
    t('FRI'),
    t('SAT'),
  ],
  months: [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ],
  shortMonths: [
    t('JAN'),
    t('FEB'),
    t('MAR'),
    t('APR'),
    t('MAY'),
    t('JUN'),
    t('JUL'),
    t('AUG'),
    t('SEP'),
    t('OCT'),
    t('NOV'),
    t('DEC'),
  ],
});
