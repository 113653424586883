import { FormatLocaleDefinition } from 'd3-format';

const NumberLocaleRUB: FormatLocaleDefinition = {
  decimal: ',',
  thousands: '\xa0',
  currency: ['', '₽'],
  grouping: [3],
};

const NumberLocaleDOLLAR: FormatLocaleDefinition = {
  decimal: '.',
  thousands: ',',
  currency: ['$', ''],
  grouping: [3],
};

const NumberLocales = {
  NumberLocaleRUB,
  NumberLocaleDOLLAR,
};
export default NumberLocales;
