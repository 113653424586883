// Resolution for this I got from https://github.com/d3/d3-format/issues/71
export const LanguagePrefixLocales = {
  ru: (v: string) =>
    v
      .replace('k', ' тыс.')
      .replace('M', ' млн')
      .replace('B', ' млрд')
      .replace('G', ' млрд')
      .replace('T', ' трлн')
      .replace('P', ' квадрлн')
      .replace('E', ' квинтлн')
      .replace('Z', ' секстлн')
      .replace('Y', ' септлн'),
};
// Global D3 formatting changes depending on the selected language
// If the language that is currently is use by user
